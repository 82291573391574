// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Button
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"

import { 
    selectStorageUnitsById
} from "../../../features/storageunit/storageUnitSlice"

import {
    selectStorageUnitSingleId, 
    setStorageUnitSingleId,
} from "../../../features/storageunit/storageUnitSingleIdSlices"

import {
    useAppDispatch 
} from "../../../hooks/useAppDispatch"


export const LastEntryStorageUnitButton = () => {  
    const { t } = useTranslation()    
    const dispatch = useAppDispatch()
    
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const editStorageUnitId = useAppSelector(selectStorageUnitSingleId)


    //initialise options
    const storageUnitIds = [] as number[]

    //For Loop over all organisations
    for (var i in storageUnits) {
        const currValue = storageUnits[i].id
       
        storageUnitIds.push(currValue)
    }        
   
    const currIndex = storageUnitIds.indexOf(editStorageUnitId)

    const onChangeBackward = () => {
         return(
            dispatch(setStorageUnitSingleId(storageUnitIds[currIndex-1]))
        )
    }
 
    const isDisabledBackward = () => { 
        if (storageUnitIds[0] === editStorageUnitId) {
            return(
                true
            )
        } else {
            return(
                false
            )
        }
    }

    
    return (
        <Button 
            onClick={() => {onChangeBackward()}}
            disabled = {isDisabledBackward()}
            fullWidth
        >
            {t("Storeroom.lastEntryButton")}
        </Button>            
    )
} 
