// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Button
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"

import { 
    selectOrganisationsById
} from "../../../features/organisation/organisationsSlice"

import { 
    selectOrganisationSingleId, 
    setOrganisationSingleId, 
} from "../../../features/organisation/organisationSingleIdSlice"

import {
    useAppDispatch 
} from "../../../hooks/useAppDispatch"


export const NextEntryOrganisationButton = () => {  
    const { t } = useTranslation()    
    const dispatch = useAppDispatch()
    
    const organisations = useAppSelector(selectOrganisationsById)
    const editOrganisationId = useAppSelector(selectOrganisationSingleId)

    //initialise options
    const organisationIds = [] as number[]

    //For Loop over all organisations
    for (var i in organisations) {
        const currValue = organisations[i].id
       
        organisationIds.push(currValue)
    }        
   
    const lengthArr = organisationIds.length
    const currIndex = organisationIds.indexOf(editOrganisationId)


    const onChangeForward = () => {
        return(
            dispatch(setOrganisationSingleId(organisationIds[currIndex+1]))
        )
    }

    const isDisabledForward = () => {
        if (organisationIds[lengthArr-1] === editOrganisationId) {
            return(
                true
            )
        } else {
            return(
                false
            )
        }    
    }

    return (
        <Button 
            onClick={() => {onChangeForward()}}
            disabled = {isDisabledForward()}
            fullWidth
        >
            {t("Storeroom.nextEntryButton")}
        </Button>             
    )
} 