// Imports from external sources
import { 
    useTranslation
} from "react-i18next"

import {  
    useNavigate 
} from "react-router-dom"

import {
    Button
} from "@mantine/core"


/**
 * The GoFromViewToUpdatePageButton component renders a button which navigate from the viewpage to the updatepage.
 */
export const GoFromToDeletePageButton = () => {    
    const { t } = useTranslation()
    const navigate = useNavigate()    
  
    return (
        <Button 
            onClick={() => navigate("../delete")}
            fullWidth
        >
            {t("Storeroom.deleteButton")}
        </Button>
    )
}