import { FieldProps } from "formik"

import DatePicker from "react-datepicker"

import { timeObjToMomentTime } from "../../util/proto"


import "react-datepicker/dist/react-datepicker.css"


interface CustomDatePickerField extends FieldProps {
  className?: string
  placeholder?: string
}

export const DatePickerField = ({
  className,
  field,
  form,
}: CustomDatePickerField) => {

  const onChange = (value: Date) => {      
        
    var myDate = new Date(value).getTime() //Time in milliseconds

    form.setFieldValue("date", {seconds: myDate/1000, nanos: 0})
  }

  const getValue = () => {
      const curValue = form.getFieldProps("date").value

      return (    
        timeObjToMomentTime(curValue).format("DD.MM.YYYY")
      )    
  }

  return (
    <DatePicker
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      minDate={new Date()}
    />
  )
}

export default DatePickerField
