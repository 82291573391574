 // Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
 import {
      useTranslation
} from "react-i18next"

import { 
    Link 
} from "react-router-dom"

import {
    Container 
} from "@mantine/core"


// Imports from vseth-canine-ui
import { 
    LoginButton 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks



/**
 * The HomePage component renders the welcome page of the application.
 */
export const HomePage = () => {
    const { t } = useTranslation()

   
    return (
        <Container>
            <section>
                <h1>{t("HomePage.title")}</h1>
            </section>
            <LoginButton />

            <section>
                <h3>{t("HomePage.reservationTitle")}</h3>
                <p>
                    {t("HomePage.catalogueText")}
                    <br/>
                    <Link         
                        to={"/catalogue/"}
                    >{t("Navbar.baseCatalogue")}                                                   
                    </Link> 
                </p>
                <p>
                    {t("HomePage.reservationText")}
                    <br/>
                    <Link         
                        to={"/reservations/"}
                    >{t("Navbar.baseReservations")}                                                   
                    </Link>                 
                </p>
            </section>

            <section>
                <h3>{t("HomePage.organisationTitle")}</h3>
                <p>
                    {t("HomePage.organisationText")}
                    <br/>
                    <Link         
                        to={"/organisations/"}
                    >{t("Navbar.baseOrganisations")}                                                   
                    </Link>     
                </p>    
            </section>

            <section>
                <h3>{t("HomePage.faqTitle")}</h3>
                <p>
                    {t("HomePage.faqText")}
                    <br/>
                    <Link         
                        to={"/faq/"}
                    >{t("Navbar.baseFAQ")}                                                   
                    </Link>   
                </p>    
            </section>
        </Container>
    )
}
