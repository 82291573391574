// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    ReactNode
} from "react"

import { 
    LoadingOverlay 
} from "@mantine/core"

// Imports from vseth-canine-ui
import { 
    AuthProvider,
} from "vseth-canine-ui"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectTokens, 
    setAuthState 
} from "../../features/auth/authSlice"

import { 
    getAuthStateFromToken 
} from "../../util/getAuthStateFromToken"


//Define interface
type CustomAuthProviderProps = {
    children: ReactNode
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const CustomAuthProvider = (props: CustomAuthProviderProps) => {
    const tokens = useAppSelector(selectTokens)
    const dispatch = useAppDispatch()

    const initConfig = {
        flow: "implicit",
        checkLoginIframe: false,
        onLoad: undefined
    } as Keycloak.KeycloakInitOptions
    
    
    return (
        <AuthProvider
            setAuthState ={(authState) => {
                dispatch(setAuthState(authState))
            }}
            authState={getAuthStateFromToken(tokens)}
            LoadingComponent={<LoadingOverlay visible={true}/>}          
            initConfig={initConfig}
        >
            {props.children}
        </AuthProvider>
    )
}
