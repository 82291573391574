// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    connect 
} from "react-redux"

import {
    withFormik,
} from "formik"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    Timestamp 
} from "../../../proto/google/protobuf/timestamp_pb"

import {
    ExternalStorageDateForm
} from "./ExternalStorageDateForm"

import {
    ExternalStorageDatesValidationSchema
} from "./ExternalStorageDateValidationSchema"

import { 
    updateExternalStorageDateMessage,
    ExternalStorageDateAttributes
} from "../../../features/externalStorageDate/externalStorageDatesSlice"


// Define interfaces
//Map Dispatch function to 
interface DispatchProps {
    update: any
}

//Title and Initial values of the form 
interface MyFormBaseProps {
    title?: string 
    externalStorageDateId: number,
    initialDate: Timestamp.AsObject | undefined,
}

type MyFormProps = MyFormBaseProps & DispatchProps


/**
 * The ExternalStorageDatesEditAdminForm component renders the ExternalStorageDatesEditAdminForm page of the application.
 */
 const formikEnhancer = withFormik<MyFormProps, ExternalStorageDateAttributes>({
    // Transform outer props into form values
    mapPropsToValues: (props) => {
        return {            
           date: props.initialDate || {seconds: 0, nanos: 0},
        }
    },

    // Validation function
    validationSchema: ExternalStorageDatesValidationSchema,

    handleSubmit: (values, { props, resetForm }) => {

        const payload = {
            date: values.date!,
        }


        props.update(props.externalStorageDateId, payload)
        resetForm({values})
    },

    displayName: 'Edit External Storage Date Form',

})(ExternalStorageDateForm)


const mapDispatchToProps = (dispatch: any) => ({
    update: (externalStorageDateId: number , values: ExternalStorageDateAttributes) => dispatch(updateExternalStorageDateMessage(externalStorageDateId, values))
})


export const ExternalStorageDateUpdateForm = connect(
    null,
    mapDispatchToProps
)(formikEnhancer)

export default ExternalStorageDateUpdateForm
