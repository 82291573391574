// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { connect } from "react-redux"

import {
    withFormik,
} from "formik"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    ExternalStorageDateForm
} from "./ExternalStorageDateForm"

import {
    ExternalStorageDatesValidationSchema
} from "./ExternalStorageDateValidationSchema"

import { 
    createExternalStorageDateMessage,
    ExternalStorageDateAttributes
} from "../../../features/externalStorageDate/externalStorageDatesSlice"


// Define interfaces
//Map Dispatch function to 
interface DispatchProps {
    create: any
}

//Title and Initial values of the form 
interface MyFormBaseProps {
    title?: string,
}

type MyFormProps = MyFormBaseProps & DispatchProps

/**
 * The ExternalStorageDatesCreateAdminForm component renders the ExternalStorageDatesCreateAdminForm page of the application.
 */
 const formikEnhancer = withFormik<MyFormProps, ExternalStorageDateAttributes>({
    // Transform outer props into form values
    mapPropsToValues: () => {
        return {            
            date: {seconds: 0, nanos: 0},
        }
    },
 
    // Validation function
    validationSchema: ExternalStorageDatesValidationSchema,

    handleSubmit: (values, { props, resetForm }) => {        
        props.create(values)
        resetForm({values})
    },

    displayName: 'Create External Storage Date Form',

})(ExternalStorageDateForm)


const mapDispatchToProps = (dispatch: any) => ({
    create: (values: ExternalStorageDateAttributes) => dispatch(createExternalStorageDateMessage(values))
})


export const ExternalStorageDateCreateForm = connect(
    null,
    mapDispatchToProps
)(formikEnhancer)

export default ExternalStorageDateCreateForm
