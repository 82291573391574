// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    ExternalStorageDateTable
} from "../../components/externalStorageDates/ExternalStorageDateTable"

import {
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    fetchAllExternalStorageDatesMessage,
    selectExternalStorageDatesById,
    selectIsLoadingExternalStorageDates,
    selectErrorExternalStorageDates
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


/**
 * The AdminExternalStorageDatesPage component renders the AdminExternalStorageDatesPage page of the application.
 */
export const AdminExternalStorageDatesPage = () => {
    const dispatch = useAppDispatch()
   
    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const isLoadingExternalStorageDates = useAppSelector(selectIsLoadingExternalStorageDates)
    const isErrorExternalStorageDates = useAppSelector(selectErrorExternalStorageDates)


    useEffect(() => {
        dispatch(fetchAllExternalStorageDatesMessage())
    }, [dispatch])

       
    //Loading Screen
    if (isLoadingExternalStorageDates) {
        return <LoadScreen />
    }


    //Error Screen
    if (isErrorExternalStorageDates) {
        return <ErrorScreen 
            customText={isErrorExternalStorageDates?.message} 
        />       
    } 
        
    
    return(      
        <Fragment>                      
            <CreateBar/>
            <ExternalStorageDateTable 
                externalStorageDatesForTable={Object.values(externalStorageDates)}
            />          
        </Fragment>      
    )      
}