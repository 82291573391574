// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"



// Imports from vseth-canine-ui
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    Timestamp 
} from "../../proto/google/protobuf/timestamp_pb"

import { 
    timeObjToMomentTime 
} from "../../util/proto"

import { 
    GetExternalStorageDateAttributes
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import {
    setExternalStorageDateSingleId
} from "../../features/externalStorageDate/externalStorageDateSingleIdSlice"
import { Fragment } from "react"


// Define interfaces
interface ExternalStorageDatesTableProps {
    externalStorageDatesForTable: GetExternalStorageDateAttributes[],
}

// const { SearchBar, ClearSearchButton } = Search


/**
 * The ExternalStorageDatesTable component renders the ExternalStorageDatesTable page of the application.
 */
 export const ExternalStorageDateTable: React.FC<ExternalStorageDatesTableProps> = ({
    externalStorageDatesForTable, 
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    // row formatters
    const timeFormatter = (cell: Timestamp.AsObject, row: GetExternalStorageDateAttributes) => {
        return (
            timeObjToMomentTime(cell).format("DD.MM.YYYY")
        )
    }   
    
    
    const columns = [
        {
            dataField: "id",
            text: t("ExternalStorageDates.id"),
            sort: true,
        },
        {
            dataField: "date",
            text: t("ExternalStorageDates.date"),
            sort: true,
            formatter: timeFormatter,
        },
    ]




    return(
        <Fragment>
         ExternalStorageDateTable
        </Fragment>

    )
}