// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useLocation
} from "react-router-dom"



// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    Timestamp
} from "../../proto/google/protobuf/timestamp_pb"


import { 
    timeObjToMomentTime 
} from "../../util/proto"

import { 
    GetExternalStorageResponse 
} from "../../features/externalStorage/externalStoragesSlice"


import {
    setExternalStorageSingleId
} from "../../features/externalStorage/externalStorageSingleIdSlice"

import {
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import { 
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    selectExternalStoragesById
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    useIsOnAdminPage 
} from '../../hooks/useIsOnAdminPage'

// Define interfaces
interface ExternalStoragesTableProps {
    externalStoragesForTable: GetExternalStorageResponse[],
    isBeforeStart?: boolean
    isBeforeEnd?: boolean
    isArchive?: boolean
    isFullTable?: boolean
}

// const { SearchBar, ClearSearchButton } = Search

/**
 * The ExternalStorageRequestsTable component renders the ExternalStorageRequestsTable page of the application.
 */
export const ExternalStorageTable : React.FC<ExternalStoragesTableProps> = ({
    externalStoragesForTable, 
    isBeforeStart,
    isBeforeEnd, 
    isArchive,
    isFullTable,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()   
    const location = useLocation()
    
    const isOnAdminPage = useIsOnAdminPage()

        //Selectors
    const externalStorages = useAppSelector(selectExternalStoragesById)
    const organisations = useAppSelector(selectOrganisationsById) 
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
   

    const isArchiveChecked = (isArchive !== undefined) ? isArchive : false

    // row formatters
    const organisationFormatter = (value: number, row: GetExternalStorageResponse) => {
        return(
            <p>{(organisations[value] === undefined) ? "": organisations[value].organisationInfo?.shortName}</p>
        )
    }


    const startTimeFormatter = (value: number, row: GetExternalStorageResponse) => {
        const startTimeId = row.externalStorageInfo!.startTimeId
        const startTime = (externalStorageDates[startTimeId] === undefined) ? {seconds: 0, nanos: 0} : externalStorageDates[startTimeId].date

        return(
            timeObjToMomentTime(startTime).format("DD.MM.YYYY")
        )
    }

    const endTimeFormatter = (value: number, row: GetExternalStorageResponse) => {
        const endTimeId = row.externalStorageInfo!.endTimeId
        const endTime = (externalStorageDates[endTimeId] === undefined) ? {seconds: 0, nanos: 0} : externalStorageDates[endTimeId].date

        return(
            timeObjToMomentTime(endTime).format("DD.MM.YYYY")
        )
    }   
    
    
    const storageUnitRelationsFormatter = (value: number, row: GetExternalStorageResponse) => {
        let storageUnitRelationsText = "" 
       
        if (externalStorages[row.id] !== undefined ) {
            let storageUnitRelations = externalStorages[row.id].externalStorageInfo!.storageUnitRelationList
            
            storageUnitRelations.forEach(relationId => {
                if (storageUnits[relationId.storageUnitId] !== undefined) {
                    storageUnitRelationsText += storageUnits[relationId.storageUnitId].storageUnitInfo?.displayName + ", "
                }
            })            
        }

        return(
            storageUnitRelationsText
        )
    }

    const confirmedFormatter = (value: number, row: GetExternalStorageResponse) => {
        if (row.confirmationTime!.seconds > 3600) {
            const confirmationTime = row.confirmationTime
            
            return (
                <Fragment>
                    <Icon 
                        icon = {ICONS.CHECK} 
                        color = "green"
                        size = {16}
                    /> 

                    <Fragment>  (</Fragment>
                    {timeObjToMomentTime(confirmationTime).format("DD.MM.YYYY")}
                    <Fragment>)</Fragment>

                </Fragment>
              
            )                
        } else {
            return (
                <Icon 
                    icon = {ICONS.CLOSE} 
                    color = "red"
                    size = {16}
                /> 
            )      
        }     
    }

    const timeFormatter = (value: Timestamp.AsObject) => {
        return (
            timeObjToMomentTime(value).format("DD.MM.YYYY HH:mm")
        )
    }    
    
        
    const columns = [
        {
            dataField: "id",
            text: t("ExternalStorages.id"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "externalStorageInfo.subject",
            text: t("ExternalStorages.subject"),
            sort: true,
        },
        {
            dataField: "externalStorageInfo.requestText",
            text: t("ExternalStorages.requestText"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "externalStorageInfo.startTime",
            text: t("ExternalStorages.startTime"),
            sort: true,
            formatter: startTimeFormatter,
            hidden: !isBeforeStart 
        },
        {
            dataField: "externalStorageInfo.endTime",
            text: t("ExternalStorages.endTime"),
            sort: true,
            formatter: endTimeFormatter,
            hidden: !isBeforeEnd
        },
        {
            dataField: "externalStorageInfo.organisationId",
            text: t("Organisations.shortName"),
            sort: true,
            formatter: organisationFormatter,
            hidden: !isOnAdminPage,
        },
        {
            dataField: "externalStorageInfo.storageUnitRelationList",
            text: t("Storeroom.storageUnits"),
            sort: true,
            formatter: storageUnitRelationsFormatter, 
        },
        {
            dataField: "createTime",
            text: t("ExternalStorages.createTime"),
            sort: true,
            formatter: timeFormatter,
            hidden: !isFullTable
        },
        {
            dataField: "createUser",
            text: t("ExternalStorages.createUser"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "updateTime",
            text: t("ExternalStorages.updateTime"),
            sort: true,
            formatter: timeFormatter,
            hidden: !isFullTable
        },
        {
            dataField: "updateUser",
            text: t("ExternalStorages.updateUser"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "confirmed",
            text: t("ExternalStorages.confirmed"),
            sort: true,
            formatter: confirmedFormatter,
            hidden: isArchiveChecked
        },
        {
            dataField: "confirmationTime",
            text: t("ExternalStorages.confirmationTime"),
            sort: true,
            formatter: timeFormatter,
            hidden: !isFullTable
        },
        {
            dataField: "confirmationUser",
            text: t("ExternalStorages.confirmationUser"),
            sort: true,
            hidden: !isFullTable
        },
    ]


    

    return(
        <Fragment>
        ExternalStorageTable
        </Fragment>
    )
}