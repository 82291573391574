export const i18n_english = {
    translation: {
        lang: "en",
        
        HomePage: {
            title: "Inventory Managementtool of VSETH",
            reservationTitle: "Reservations Management",
            catalogueText: "The catalogue page shows all items which you can reserved from your own organisation and all other organisations which are merked for external reservation.",
            reservationText: "The reservation page shows the reservation which you have orderd or which you have confirmed.",
            organisationTitle: "Inventory Management of your organsiations",
            organisationText: "On this page you can manage the places, storage units, items, reservations and external storage requests of your organisations.",
            faqTitle: "Frequently asked questions (FAQ)",
            faqText: "The frequently asked questions page provides information on frequent questions or concerns.",
        },
                        
        Navbar: {
            adminItem: "Admin",
            baseHome: "Home",
            baseCatalogue: "Catalogue",
            baseOrganisations: "Organisations",
            baseReservations: "My Reservations",
            baseFAQ: "FAQ",
        },

        Footer: {
            privacy: "Privacy Policy",
            impressum: "Imprint",
        },

        LoadScreen: {
            loading: "",
        },

        ErrorScreen: {
            title: "Error",
            text: "",
        }, 

        //General Translations
            //for the whole Site
            Storeroom: {
                organisations: "Organisations",
                organisation: "Organisation",
                places: "Places",
                storageUnits: "Storage Units",
                storageUnit: "Storage Unit",
                items: "Items",
                item: "Item",
                tags: "Tags",
                reservations: "Reservations",
                externalStorage: "External Storage",
                externalStorageDates: "External Storage - Dates",
                users: "Users",
                createButton: "Create",
                viewButton: "View",
                tableButton: "Table",
                updateButton: "Edit",
                deleteButton: "Delete",
                confirmButton: "Confirm",
                unconfirmButton: "Unconfirm",
                saveButton: "Save",
                cancelButton: "Cancel",
                backButton: "Back",
                lastEntryButton: "Last Entry",
                nextEntryButton: "Next Entry",
                saveCloseButton: "Save & Close",
                fullTableButton: "Complete Table",
                normalViewTableButton: "Reduced Table",
                lastChangeInfo: "Last Change",
                lastChangeDate: "Date",
                lastChangeUser: "User",
                lastChangeDateCombined: "Last Change: Date",
                lastChangeUserCombined: "Last Change: User",
                created: "Created",
                chooseOne: 'Choose one',
                yes: 'Yes',
                no: 'No',                
                selectOption: 'Select an option',
            },

            //General Translations for Organisations
            Organisations: {
                id: "ID",
                shortName: "Shortname",
                title: "Name",
                logoPath: "Group for the Logo",
                contactMail: "E-mail for rental",
                contactPhone: "Phone number for rental",
                contactAddress: "Address for rental",
                contactAddressBuilding: 'Building',
                contactAddressFloor: 'Floor',
                contactAddressRoom: 'Room',
                contactAddressStreet: 'Street',                
                contactAddressNumber: 'Number',
                contactAddressPLZ: 'PLZ',
                contactAddressCity: 'City',
                updateRole: "Edit Role",
                viewRole: "View Role",
                createText: "Create Organisation",
                viewText: "View Organiation",
                updateText: "Edit Organisation",
                deleteText: "Please confirm the deletion of this Organisation", 
                myOrganisations: "My organisations",
            }, 

            //General Translations for Places
            Places: {
                id: "ID",
                room: "Room",
                rack: "Rack",
                description: "Description",
                createText: "Create Place",
                viewText: "View Place",
                updateText: "Edit Place",
                deleteText: "Please confirm the deletion of this Place",
            },

            //General Translations for Storage Units
            StorageUnits: {
                id: "ID",
                displayName: "Name",
                description: "Description",
                storageUnitType: "Storage Unit Type",
                isInStorageUnitId: "is in Storage Unit",
                notInOne: "not in a Storage Unit",
                typeOther: "Other",
                typeBox: "Eurobox",
                typePalett: "Europalett",
                createText: "Create Storage Unit",
                viewText: "View Storage Unit",
                updateText: "Edit Storage Unit",
                deleteText: "Please confirm the deletion of this Storage Unit",
                printSingleLabelButton: "Single label",
                printAllLabelButton: "All labels",
                printSingleText: "Print single label for box",
                printAllText: "Print labels for all boxes",
                loadingDocument: "labels are created...",
                downloadNow: "download now!",
            },


            //General Translations for Items
            Items: {
                id: "ID",
                displayName: "Name",
                description: "Description",
                externalRent: "External rent",
                createTime: "Creation date",
                deleted: "Deleted",
                reservationList: "List of reservations",
                createText: "Create Item",
                viewText: "View Item",
                updateText: "Edit Item",
                deleteText: "Please confirm the deletion of this Item",
                storageUnitRelation: "In Storage Unit (Quantity)",
                quantity: "quantity",
                addNewOne: "Add new one",
                deletedItems: "Overview of the deleted items"
            },

            //General Translations for Tags
            Tags: {
                id: "ID",
                name: "Name",
                createText: "Create Tag",
                updateText: "Edit Tag",
                deleteText: "Please confirm the deletion of this Tag",
            },

            //General Translations for Reservations
            Reservations: {
                id: "ID",
                subject: "Subject",
                requestText: "Request",
                startTime: "Start Date",
                endTime: "Ende Date",
                createTime: "Creation date",
                createUser: "Creation user",
                updateTime: "Update Time",
                updateUser: "Update User",
                itemRelationsList: "Items (Confirmed?)",            
                createText: "Create request for the reservation",
                updateText: "Edit request for the reservation",
                deleteText: "Please confirm the deletion of this reservation request",
                confirmText: "Please confirm this reservation request",
                beforeStart: "Pending reservation requests",
                beforeEnd: "Current reservations requests", 
                archive: "Archive",
                organisationRequests: "Requests of the Organisation",
                ownedItems: "Requests including items owned by the organisation",
                myRequests: "My Reservation Requests",
                myConfirmed: "My Confirmed Reservation Requests",
            },

            //General Translations for ExternalStorageRequests
            ExternalStorages: {
                id: "ID",
                subject: "Subject",
                requestText: "Request",
                startTime: "Start Date",
                endTime: "Ende Date",
                createTime: "Creation date",
                createUser: "Creation user",
                updateTime: "Update Time",
                updateUser: "Update User",
                confirmed: "Confirmed",
                confirmationTime: "Confirmation Time",
                confirmationUser: "Confirmation User",
                createText: "Create request for the external storage",
                updateText: "Edit request for the external storage",
                deleteText: "Please confirm the deletion of this external storage request",
                confirmText: "Please confirm this external storage request",
                beforeStart: "Sending to external storage",
                beforeEnd: "In external storage", 
                archive: "Archive",
            },

            //General Translations for ExternalStorageDates
            ExternalStorageDates: {
                id: "ID",
                date: "Date",
                createText: "Create date for external storage transport",
                updateText: "Edit date for external storage transport",
                deleteText: "Please confirm the deletion of this date for external storage transport",

            },

            //General Translations for Users
            Users: {
                id: "ID",
                uuid: "uuid",
                language: "language",
                activeOrganisation: "Active Organisation"
            },


        //Specific Translations for Components
            //Admin
            AdminPage: {
                title: "Admin Area",
            }, 
            
            Catalogue: {
                title: "Catalogue",
                noUserRole: "No userrole is definied to your account. Therefore, you are not authorized to do reservations of items.",
            },

            NoUserRolePage: {
                info: "No userrole is definied to your account. Therefore, you are not authorized to access this site.",
            },

            NotAuthorizedPage: {
                info: "You are not authorized to access this site."
            }
        

    },
}
