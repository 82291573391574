// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { Container } from "@mantine/core"
import {
	LoginButton,
	useAuth,
} from "vseth-canine-ui"


// Define interfaces


/**
 * The LoginPage component renders the LoginPage page of the application.
 */
export const LoginPage = () => {
    const { authState } = useAuth()
   
    return (
        <Container>
            <h1>
                Hello {authState.userInfo?.given_name}{" "}
                {authState.userInfo?.family_name} !
            </h1>
            <LoginButton />
        

        </Container>
       
    )
  }