// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageDateUpdateForm 
} from "../../components/externalStorageDates/forms/ExternalStorageDateEditForm"

import { 
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    selectExternalStorageDateSingleId, 
} from "../../features/externalStorageDate/externalStorageDateSingleIdSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    LastEntryExternalStorageDateButton 
} from "../../components/buttons/lastEntry/LastEntryExternalStorageDateButton"

import { 
    NextEntryExternalStorageDateButton
} from "../../components/buttons/nextEntry/NextEntryExternalStorageDateButton"

import { 
    GoFromToDeletePageButton
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

/**
 * The UpdateExternalStorageDatesPage component renders the UpdateExternalStorageDates page of the application.
 */
export const UpdateExternalStorageDatesPage = () => {
    const { t } = useTranslation()

    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const editExternalStorageDateId = useAppSelector(selectExternalStorageDateSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editExternalStorageDateId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }
   

    return(
        <Card>     
            <h1>{t("ExternalStorageDates.updateText")}</h1>
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryExternalStorageDateButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryExternalStorageDateButton/>}
            /> 
            <ExternalStorageDateUpdateForm 
                externalStorageDateId = {editExternalStorageDateId}
                initialDate = {externalStorageDates[editExternalStorageDateId].date!}
            />
        </Card>            
    )
}