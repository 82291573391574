// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    FormikProps,
    Form,
    Field
} from "formik"


// Imports from vseth-canine-ui

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageDateAttributes
} from "../../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    SaveButton 
} from "../../buttons/form/SaveButton"

import { 
    SaveCloseButton
} from "../../buttons/form/SaveBackButton"

import { 
    CancelButton 
} from "../../buttons/form/CancelButton"

import DatePickerField from "../../general/DatePickerField"


// Define interfaces
interface OtherProps {
    title?: string 
}


/**
 * The Page component renders the admin page of the application.
 */
//Organisation Form
export const ExternalStorageDateForm = (props: OtherProps & FormikProps<ExternalStorageDateAttributes>) => {
    const { t } = useTranslation()

    const {  
        touched, 
        errors, 
        title,
    } = props

    return (
        <Form onSubmit={props.handleSubmit}>
            <h3>{title}</h3>
            <div className="row" >
                <div className="position-relativ col-lg-6">
                </div>
                <div className="position-relativ col-lg-2">
                    <SaveButton />
                </div>
                <div className="position-relativ col-lg-2">
                    <SaveCloseButton/> 
                </div>
                <div className="position-relativ col-lg-2">
                    <CancelButton/>
                </div>
            </div>  
            <div className="row" >
                <div className="position-relativ col-lg-12">
                    <h6>{t("ExternalStorageDates.date")}</h6>
                    <Field 
                        name="date" 
                        id="date"
                        component={DatePickerField}
                        placeholder="Choose Date"                        
                    />
                    {touched.date && errors.date && <div>{errors.date}</div>}        
                </div>
            </div>
        </Form>
    )
}
