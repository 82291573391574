// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks



export const PAGE_SIZES = [5, 10, 15, 20, 50, 100]


export const clientId = "com_prod_vseth_lager_backend_backend"


export const webapi = "https://webapi.storerooms.vseth.ethz.ch"