// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata 
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    CreateExternalStorageRequest as createExternalStorageRequest, 
    CreateExternalStorageResponse as createExternalStorageResponse,
    DeleteExternalStorageRequest as deleteExternalStorageRequest,
    DeleteExternalStorageResponse as deleteExternalStorageResponse,
    UpdateExternalStorageRequest as updateExternalStorageRequest,
    UpdateExternalStorageResponse as updateExternalStorageResponse,
    ConfirmExternalStorageRequest as confirmExternalStorageRequest,
    ConfirmExternalStorageResponse as confirmExternalStorageResponse,
    GetExternalStorageRequest as getExternalStorageRequest,
    GetExternalStorageResponse as getExternalStorageResponse,
    ListOrganisationExternalStoragesRequest as listOrganisationExternalStoragesRequest,
    ListOrganisationExternalStoragesResponse as listOrganisationExternalStoragesResponse,
    ListExternalStoragesRequest as listExternalStorageRequest,
    ListExternalStoragesResponse as listExternalStorageResponse,
    ExternalStorageAttributes as externalStorageAttributes,
    ExternalStorageStorageUnitRelationAttributes as externalStorageStorageUnitRelationAttributes
} from "../../proto/sip/storeroom/storeroom_pb"




// Define the Types
export type ExternalStorageAttributes = externalStorageAttributes.AsObject
type CreateExternalStorageResponse = createExternalStorageResponse.AsObject
type UpdateExternalStorageResponse = updateExternalStorageResponse.AsObject
type DeleteExternalStorageResponse = deleteExternalStorageResponse.AsObject
type ConfirmExternalStorageResponse = confirmExternalStorageResponse.AsObject
type ListOrganisationExternalResponse = listOrganisationExternalStoragesResponse.AsObject
export type GetExternalStorageResponse = getExternalStorageResponse.AsObject
export type ListExternalStorageResponse = listExternalStorageResponse.AsObject


type ExternalStorageRequestDict = {
    [Key: number]: GetExternalStorageResponse
}


// Define which objects the externalStorageRequest State have. 
interface ExternalStorageRequestState {
    items: ExternalStorageRequestDict
    isLoading: boolean
    error?: Error
}


// Define intital state
const initialState: ExternalStorageRequestState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const externalStorages = createSlice({
    name: "externalStorages",
    initialState,
    reducers: { 

        // Fetch all externalStorage
        fetchAllExternalStoragesStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllExternalStoragesSuccess: (state, { payload }: PayloadAction<ListExternalStorageResponse>) => {
            state.items = {}
            payload.externalStoragesList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllExternalStoragesFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch all externalStorage of an organisationb
        fetchAllOrganisationExternalStoragesStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllOrganisationExternalStoragesSuccess: (state, { payload }: PayloadAction<ListOrganisationExternalResponse>) => {
            state.items = {}
            payload.externalStoragesList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllOrganisationExternalStoragesFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch one externalStorage
        fetchExternalStorageStart: (state) => {
            state.isLoading = true            
            state.error = undefined
        },
        fetchExternalStorageSuccess: (state, { payload }: PayloadAction<GetExternalStorageResponse>) => {
            state.items[payload.id] = payload
            state.isLoading = false 
            state.error = undefined           
        },
        fetchExternalStorageFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Create ExternalStorage
        createExternalStorageStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createExternalStorageSuccess: (state, { payload }: PayloadAction<CreateExternalStorageResponse>) => {
            state.items[payload.externalStorage!.id] = payload.externalStorage!
            state.isLoading = false
            state.error = undefined
        },
        createExternalStorageFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Update ExternalStorage      
        updateExternalStorageStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateExternalStorageSuccess: (state, { payload }: PayloadAction<UpdateExternalStorageResponse>) => {
            state.items[payload.externalStorage!.id] = payload.externalStorage!
            state.isLoading = false
            state.error = undefined
        },
        updateExternalStorageFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete ExternalStorage
        deleteExternalStorageStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteExternalStorageSuccess: (state, { payload }: PayloadAction<DeleteExternalStorageResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteExternalStorageFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Confirm ExternalStorage      
        confirmExternalStorageStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        confirmExternalStorageSuccess: (state, { payload }: PayloadAction<ConfirmExternalStorageResponse>) => {
            state.items[payload.externalStorage!.id] = payload.externalStorage!
            state.isLoading = false
            state.error = undefined
        },
        confirmExternalStorageFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})


// Export Reducers
export default externalStorages.reducer


// Define Actions
const {
    fetchAllExternalStoragesStart,
    fetchAllExternalStoragesSuccess,
    fetchAllExternalStoragesFailure,

    fetchAllOrganisationExternalStoragesStart,
    fetchAllOrganisationExternalStoragesSuccess,
    fetchAllOrganisationExternalStoragesFailure,
    
    fetchExternalStorageStart,
    fetchExternalStorageSuccess,
    fetchExternalStorageFailure,

    createExternalStorageStart,
    createExternalStorageSuccess,
    createExternalStorageFailure,    
        
    updateExternalStorageStart,
    updateExternalStorageSuccess,
    updateExternalStorageFailure,

    deleteExternalStorageStart,
    deleteExternalStorageSuccess,
    deleteExternalStorageFailure,

    confirmExternalStorageStart,
    confirmExternalStorageSuccess,
    confirmExternalStorageFailure,

} = externalStorages.actions


// Fetch all externalStorages
export const fetchAllExternalStoragesMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllExternalStoragesStart())
    const token = selectAccessToken(getState())
    const request = new listExternalStorageRequest()

    return storeroomClient
        .listExternalStorages(request, getAuthMetadata(token))
        .then((response: listExternalStorageResponse) => {
            dispatch(fetchAllExternalStoragesSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllExternalStoragesFailure(err))
        })
}


// Fetch all externalStorages of an organisation
export const fetchAllOrganisationExternalStoragesMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOrganisationExternalStoragesStart())
    const token = selectAccessToken(getState())
    const request = new listOrganisationExternalStoragesRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listOrganisationExternalStorages(request, getAuthMetadata(token))
        .then((response: listOrganisationExternalStoragesResponse) => {
            dispatch(fetchAllOrganisationExternalStoragesSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOrganisationExternalStoragesFailure(err))
        })
}


// Fetch one externalStorage
export const fetchExternalStorageMessage = (externalStorageId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchExternalStorageStart())
    const token = selectAccessToken(getState())
    const request = new getExternalStorageRequest()
    request.setId(externalStorageId)

    return storeroomClient
        .getExternalStorage(request, getAuthMetadata(token))
        .then((response: getExternalStorageResponse) => {
            dispatch(fetchExternalStorageSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchExternalStorageFailure(err))
        })
}


// Create externalStorage
export const createExternalStorageMessage = (externalStorage: ExternalStorageAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createExternalStorageStart())
    const token = selectAccessToken(getState())
     

    const storageunitrelation = new Array<externalStorageStorageUnitRelationAttributes>()
    //For Loop over all storageunitrelations
    for (var i in externalStorage.storageUnitRelationList) {
         const currStorageunit = externalStorage.storageUnitRelationList[i].storageUnitId
        
        let storageunitrelationItem = new externalStorageStorageUnitRelationAttributes()
        storageunitrelationItem.setId(0)
        storageunitrelationItem.setStorageUnitId(currStorageunit)
        storageunitrelationItem.setExternalStorageId(0)

        storageunitrelation.push(storageunitrelationItem)
    }    
    
    const externalStorageData = new externalStorageAttributes()
    externalStorageData.setSubject(externalStorage.subject)
    externalStorageData.setRequestText(externalStorage.requestText)
    externalStorageData.setStartTimeId(externalStorage.startTimeId)
    externalStorageData.setEndTimeId(externalStorage.endTimeId)
    externalStorageData.setOrganisationId(externalStorage.organisationId)
    externalStorageData.setStorageUnitRelationList(storageunitrelation)
         
    const request = new createExternalStorageRequest()
    request.setExternalStorageInfo(externalStorageData)

    return storeroomClient
        .createExternalStorage(request, getAuthMetadata(token))
        .then((response: createExternalStorageResponse) => {
            dispatch(createExternalStorageSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createExternalStorageFailure(err))
        })
}


// Update externalStorage
export const updateExternalStorageMessage = (externalStorageId: number, externalStorage: ExternalStorageAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateExternalStorageStart())
    const token = selectAccessToken(getState())

    const storageunitrelation = new Array<externalStorageStorageUnitRelationAttributes>()
    //For Loop over all storageunitrelations
    for (var i in externalStorage.storageUnitRelationList) {
         const currStorageunit = externalStorage.storageUnitRelationList[i].storageUnitId
        
        let storageunitrelationItem = new externalStorageStorageUnitRelationAttributes()
        storageunitrelationItem.setId(0)
        storageunitrelationItem.setStorageUnitId(currStorageunit)
        storageunitrelationItem.setExternalStorageId(0)

        storageunitrelation.push(storageunitrelationItem)
    }    
    
    const externalStorageData = new externalStorageAttributes()
    externalStorageData.setSubject(externalStorage.subject)
    externalStorageData.setRequestText(externalStorage.requestText)
    externalStorageData.setStartTimeId(externalStorage.startTimeId)
    externalStorageData.setEndTimeId(externalStorage.endTimeId)
    externalStorageData.setOrganisationId(externalStorage.organisationId)
    externalStorageData.setStorageUnitRelationList(storageunitrelation)
   

    const request = new updateExternalStorageRequest()
    request.setId(externalStorageId)
    request.setExternalStorageInfo(externalStorageData)

    return storeroomClient
        .updateExternalStorage(request, getAuthMetadata(token))
        .then((response: updateExternalStorageResponse) => {
            dispatch(updateExternalStorageSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateExternalStorageFailure(err))
        })
}


// Delete externalStorage
export const deleteExternalStorageMessage = (externalStorageId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteExternalStorageStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteExternalStorageRequest()
    request.setId(externalStorageId)

    return storeroomClient
        .deleteExternalStorage(request, getAuthMetadata(token))
        .then((response: deleteExternalStorageResponse) => {
            dispatch(deleteExternalStorageSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteExternalStorageFailure(err))
        })
}


// Update externalStorage
export const confirmExternalStorageMessage = (externalStorageId: number, confirmed: boolean): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(confirmExternalStorageStart())
    const token = selectAccessToken(getState())
     
    const request = new confirmExternalStorageRequest()
    request.setExternalStorageId(externalStorageId)
    request.setConfirmed(confirmed)

    return storeroomClient
        .confirmExternalStorage(request, getAuthMetadata(token))
        .then((response: confirmExternalStorageResponse) => {
            dispatch(confirmExternalStorageSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(confirmExternalStorageFailure(err))
        })
}


// Selectors
type ExternalStorageSliceRoot = {
    externalStorages: ReturnType<typeof externalStorages.reducer>
}

export const selectExternalStoragesById = (state: ExternalStorageSliceRoot) =>
    state.externalStorages.items
    
export const selectIsLoadingExternalStorages = (state: ExternalStorageSliceRoot) => state.externalStorages.isLoading
export const selectErrorExternalStorages = (state: ExternalStorageSliceRoot) => state.externalStorages.error