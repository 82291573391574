// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    selectExternalStorageDateSingleId, 
} from "../../features/externalStorageDate/externalStorageDateSingleIdSlice"

import {
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    DeleteExternalStorageDateBar 
} from "../../layouts/menuBars/externalStorageDate/DeleteExternalStorageDateBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToUpdatePageButton 
} from '../../components/buttons/goFromTo/GoFromToUpdatePageButton'

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    LastEntryExternalStorageDateButton 
} from "../../components/buttons/lastEntry/LastEntryExternalStorageDateButton"

import { 
    NextEntryExternalStorageDateButton
} from "../../components/buttons/nextEntry/NextEntryExternalStorageDateButton"


/**
 * The DeleteExternalStorageDatesPage component renders the DeleteExternalStorageDates page of the application.
 */
export const DeleteExternalStorageDatesPage = () => {
    const { t } = useTranslation()

    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const editExternalStorageDateId = useAppSelector(selectExternalStorageDateSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editExternalStorageDateId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }
                               
    return(
        <Card>   
            <h1>{t("Organisations.deleteText")}</h1>
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryExternalStorageDateButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToUpdatePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryExternalStorageDateButton/>}
            /> 
            {/* <ExternalStorageDateViewLayout
                externalStorageDate = {externalStorageDates[editExternalStorageDateId].date}
            /> */}
            <DeleteExternalStorageDateBar
                editExternalStorageDateId = {editExternalStorageDateId}
            />
        </Card>     
    )
}