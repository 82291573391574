// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageDateCreateForm  
} from "../../components/externalStorageDates/forms/ExternalStorageDateCreateForm"


/**
 * The CreateExternalStorageDatesPage component renders the CreateExternalStorageDates page of the application.
 */
export const CreateExternalStorageDatesPage = () => {
    const { t } = useTranslation()

    return(
        <Card>  
            <h1>{t("Organisations.createText")}</h1> 
            <ExternalStorageDateCreateForm/>
        </Card>      
    )
}